// Actions
const SHOW_MODAL = "SHOW_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const START_LOADER = "START_LOADER";
const STOP_LOADER = "STOP_LOADER";
const PAGE_TITLE_CHANGE = "PAGE_TITLE_CHANGE";
const MODEL_ON = "ON";
const MODEL_OFF = "OFF";
const JD_MODEL_ON = "JD_MODEL_ON";
const JD_MODEL_OFF = "JD_MODEL_OFF";


const CONTACT_MODAL_ON = "CONTACT_MODAL_ON";
const CONTACT_MODAL_OFF = "CONTACT_MODAL_OFF";

const API_MODAL_ON = "API_MODAL_ON"
const API_MODAL_OFF = "API_MODAL_OFF"



// Action Creators
export function showModal() {
  return { type: SHOW_MODAL };
}

export function api_modalOn(){
  return { type: API_MODAL_ON}
}

export function api_modalOff(){
  return { type: API_MODAL_OFF}
}

export function ctc_modalOn() {
  return { type: CONTACT_MODAL_ON };
}

export function ctc_modalOff() {
  return { type: CONTACT_MODAL_OFF };
}


export function closeModal() {
  document.body.classList.remove("modal-open");
  return { type: CLOSE_MODAL };
}

export function startLoader() {
  return { type: START_LOADER };
}

export function stopLoader() {
  return { type: STOP_LOADER };
}

export function changePageTitle(title) {
  document.title = "Prep AI | " + title;
  return { type: PAGE_TITLE_CHANGE, title };
}

//CV PARSER MODEL ON
export function model_on() {
  return { type: MODEL_ON };
}

export function model_off() {
  return { type: MODEL_OFF };
}

//JD MATCHER MODEL ON
export function jd_model_on() {
  return { type: JD_MODEL_ON };
}

export function jd_model_off() {
  return { type: JD_MODEL_OFF };
}

const initialState = {
  modelShow: false,
  refreshing: true,
  model: false,
  page_title: "Dashboard",
  UserName: "",
  jd_model: false,
  contact_modal:false,
  api_modal: false
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case SHOW_MODAL:
      return modalOpen(state);
    case CLOSE_MODAL:
      return modalClose(state);
    case START_LOADER:
      return loaderStart(state);
    case STOP_LOADER:
      return loaderStop(state);
    case PAGE_TITLE_CHANGE:
      return pageTitleChange(state, payload);
    case MODEL_ON:
      return ModelOn(state);
    case MODEL_OFF:
      return ModelOff(state);
    case JD_MODEL_ON:
      return Jd_modelOn(state);
    case JD_MODEL_OFF:
      return Jd_modelOff(state);
    case CONTACT_MODAL_ON:
      return contactOn(state)  
    case CONTACT_MODAL_OFF:
      return contactOff(state)
    case API_MODAL_ON:
      return apiModalOpen(state)
    case API_MODAL_OFF:
      return apiModalClose(state)
    default:
      return state;
  }
}

function modalOpen(state) {
  return {
    ...state,
    modelShow: true,
  };
}

function contactOn(state) {
  return {
    ...state,
    contact_modal: true,
  };
}

function contactOff(state) {
  return {
    ...state,
    contact_modal: false,
  };
}

function apiModalOpen(state) {
  return {
    ...state,
    api_modal: true
  }
}

function apiModalClose(state) {
  return {
    ...state,
    api_modal: false
  }
}

function modalClose(state) {
  return {
    ...state,
    modelShow: false,
  };
}

function loaderStart(state) {
  return {
    ...state,
    refreshing: true,
  };
}
function loaderStop(state) {
  return {
    ...state,
    refreshing: false,
  };
}

function pageTitleChange(state, payload) {
  return {
    ...state,
    page_title: payload,
  };
}

// CV PARSER MODEL ON
function ModelOn(state) {
  return {
    ...state,
    model: true,
  };
}

function ModelOff(state) {
  return {
    ...state,
    model: false,
  };
}

// CV PARSER MODEL ON
function Jd_modelOn(state) {
  return {
    ...state,
    jd_model: true,
  };
}

function Jd_modelOff(state) {
  return {
    ...state,
    jd_model: false,
  };
}
